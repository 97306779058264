const insertDataInHtml = (data, loadSpan) => {
  const htmlElement = loadSpan;

  // Display data only in the span children
  htmlElement.querySelector("span").innerHTML = data;
};

const queryProductLoad = async (ajaxObj, e) => {
  const data = new FormData();

  data.append("action", "varProductLoadIndexAjaxCallback");
  data.append("nonce", ajaxObj.ajax_nonce);
  data.append("variation_id", e.target.value);
  data.append("variation_name", e.target.name);

  try {
    const response = await fetch(ajaxObj.ajax_url, {
      method: "POST",
      credentials: "same-origin",
      body: data,
    });

    return await response.json();
  } catch (error) {
    return error;
    // console.log("error ", error);
  }
};

const variableProductLoadIndex = () => {
  // check if you are on a single page
  if (!document.body.classList.contains("single-product")) {
    return;
  }

  // check for the ajax form data
  const wpAjaxObject = tireLoadIndexAjax; // eslint-disable-line

  if (!wpAjaxObject || Object.keys(wpAjaxObject).length === 0) {
    return;
  }

  const variationBtn = document.querySelector(
    "form.variations_form table.variations select"
  );
  const loadSpan = document.getElementById("single-product-extras-load");

  // check if you are on a variable product page
  if (!variationBtn || !loadSpan) {
    return;
  }

  // Check the change on the select of variation
  variationBtn.onchange = async (e) => {
    // fetch data from WordPress
    const response = await queryProductLoad(wpAjaxObject, e);

    // Send data to be output on screen
    insertDataInHtml(response.data, loadSpan);
  };
};

// Start the script on window load
window.addEventListener("load", () => {
  // eslint-disable-line
  variableProductLoadIndex();
});
